<script>
    import PageHeader from "@/components/structure/page-header";
    import appConfig from "@/app.config";

    /**
     * Contacts-grid component
     */
    export default {
        page: {
            title: "Hall Of Fame",
            meta: [{ name: "description", content: appConfig.description }]
        },
        name:'hallOfFame',
        components: {  PageHeader },
        data() {
            return {
                userGridData: [
                    {
                        id: 1,
                        font: 'D',
                        name: 'David McHenry',
                        text: 'UI/UX Designer',
                        projects: ['Photoshop', 'illustrator'],
                        email: 'david@skote.com'
                    },
                    {
                        id: 2,
                        image: "http://via.placeholder.com/640x480.png/008811?text=user",
                        name: 'Frank Kirk',
                        text: 'Frontend Developer',
                        projects: ['Html', 'Css', '2 + more'],
                        email: 'frank@skote.com'
                    },
                    {
                        id: 3,
                        image: "http://via.placeholder.com/640x480.png/008811?text=user",
                        name: 'Rafael Morales',
                        text: 'Backend Developer',
                        projects: ['Php', 'Java', 'Python'],
                        email: 'Rafael@skote.com'
                    },
                    {
                        id: 4,
                        font: 'M',
                        name: 'Mark Ellison',
                        text: 'Full Stack Developer',
                        projects: ['Ruby', 'Php', '2 + more'],
                        email: 'mark@skote.com'
                    },
                    {
                        id: 5,
                        image: "http://via.placeholder.com/640x480.png/008811?text=user",
                        name: 'Minnie Walter',
                        text: 'Frontend Developer',
                        projects: ['Html', 'Css', '2 + more'],
                        email: 'minnie@skote.com'
                    },
                    {
                        id: 6,
                        image:"http://via.placeholder.com/640x480.png/008811?text=user",
                        name: 'Shirley Smith',
                        text: 'UI/UX Designer',
                        projects: ['Photoshop', 'illustrator'],
                        email: 'shirley@skote.com'
                    },
                    {
                        id: 7,
                        font: 'J',
                        name: 'John Santiago',
                        text: 'Full Stack Developer',
                        projects: ['Ruby', 'Php', '2 + more'],
                        email: 'john@skote.com'
                    },
                    {
                        id: 8,
                        image: "http://via.placeholder.com/640x480.png/008811?text=user",
                        name: 'Colin Melton',
                        text: 'Backend Developer',
                        projects: ['Php', 'Java', 'Python'],
                        email: 'colin@skote.com'
                    },
                ],
                title: "Hall Of Fame",
            };
        }
    };
</script>

<template>
    <div>
        <PageHeader :title="$t('hall_of_fame')"  />
        <div class="row">
            <div v-for="user in userGridData" :key="user.id" class="col-xl-3 col-sm-6">
                <div class="card text-center">
                    <div class="card-body">
                        <div v-if="user.font" class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">{{user.font}}</span>
                        </div>
                        <div v-if="user.image" class="mb-4">
                            <img class="rounded-circle avatar-sm" :src="`${user.image}`" alt />
                        </div>
                        <h5 class="font-size-15">
                            <a href="javascript: void(0);" class="text-dark">{{user.name}}</a>
                        </h5>
                        <p class="text-muted">{{user.text}}</p>

                        <div class="group-details  pb-3 d-none d-md-block">
                            <ul class="d-flex align-items-center justify-content-between list-inline m-0 p-0">
                                <li class="pl-3 pr-3">
                                    <p class="mb-0">{{$t('profil_user.score')}}</p>
                                    <h6>300</h6>
                                </li>
                                <li class="pl-3 pr-3">
                                    <p class="mb-0">{{$t('profil_user.level')}}</p>
                                    <h6>21</h6>
                                </li>
                                <li class="pl-3 pr-3">
                                    <p class="mb-0">{{$t('badges.text')}}</p>
                                    <h6>19</h6>
                                </li>
                            </ul>
                        </div>
                        <div class="group-member d-none d-md-block">
                            <div class="iq-media-group">
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <a href="#" class="iq-media">
                                    <img class="img-fluid avatar-xs rounded-circle" src="http://via.placeholder.com/640x480.png/008811?text=user" alt="">
                                </a>
                                <span class="text-muted ml-4">+ 200 {{$t('profil_user.followers')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-transparent border-top">
                        <div class="contact-links d-flex font-size-20">
                            <div class="flex-fill">
                                <a v-b-tooltip.hover.top :title="$t('profil_user.follow')" href="javascript: void(0);">
                                    <i class="bx bx-like"></i>
                                </a>
                            </div>

                            <div class="flex-fill">
                                <a v-b-tooltip.hover.top :title="$t('profil_user.profil')" href="javascript: void(0);">
                                    <i class="bx bx-user-circle"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <!-- end row -->
    </div>
</template>
<style scoped>
    .iq-media-group .iq-media:hover {
        z-index: 9;
    }
    .iq-media:hover {
        text-decoration: none;
    }
    .iq-media-group .iq-media {
        margin-left: -15px;
        position: relative;
        z-index: 0;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
    }
    .iq-media {
        display: inline-flex;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }






</style>